/* -----------------------------------
		      1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;
  scroll-behavior: smooth;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  text-align: center;
  font-family: "Mulish", sans-serif;
  color: #fff;
  background: #041110;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

.mt-30 {
  margin-top: 30px;
}

a,
a:hover {
  color: #fff;
  outline: none;
  text-decoration: none;
}

p {
  color: #92939e;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
}

img,
video {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.Toastify__toast-body {
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

.justify-center {
  justify-content: center;
}

/* Button */
.btn-st {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: rgb(17, 17, 17);
  padding: 17px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(90deg,
      rgb(29, 255, 150) 0%,
      rgb(188, 255, 123) 100%);
  backdrop-filter: blur(10px);
  text-transform: uppercase;
  transition: 0.3s;
}

.btn-st span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-st span img {
  max-width: 20px;
}

.btn-st:hover {
  opacity: 0.5;
}

/* -----------------------------------
                2. NavBar Section
  ----------------------------------- */
.navbar-custom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 999;
}

.navbar-custom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-custom .container .left-side {
  display: flex;
  align-items: center;
}

.navbar-custom .container .left-side .logo img {
  width: 100px;
}

.navbar-custom .container .right-side {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-custom .container .right-side .social {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-custom .container .right-side .social a {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.navbar-custom .container .right-side .social a img {
  width: 20px;
}

.navbar-custom .container .right-side .social a:hover {
  opacity: 0.5;
}

.navbar-custom .container .info {
  display: flex;
  flex-direction: column;
}

.navbar-custom.fixed {
  position: fixed;
  height: 80px;
  background-color: #000;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
}

.dropdown-toggle {
  padding: 10px 15px;
  background: #0f4a35;
  border-radius: 5px;
  height: 40px;
}

.dropdown-menu {
  padding: 5px;
  background: #293434;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.dropdown-item:hover {
  color: #fff;
  background-color: #41504f;
}

.dropdown-item img {
  max-width: 15px;
}

/* Main */
.main {
  position: relative;
  min-height: 100vh;
  padding: 130px 0px 60px;
  overflow: hidden;
  z-index: 0;
}

.main .content h1 {
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(188, 255, 123) 100%) text;
  -webkit-text-fill-color: transparent;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 60px;
  margin-bottom: 50px;
  font-weight: 900;
  color: rgb(255, 255, 255);
}

.main .content p {
  margin-bottom: 32px;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: rgb(255, 255, 255);
}

.main .content .presale-card-wrapper {
  position: relative;
  width: 800px;
  max-width: 100%;
  margin: 0px auto;
  z-index: 1;
}

.main .content .presale-card-wrapper::before {
  content: "";
  position: absolute;
  top: 15%;
  left: -23%;
  width: 350px;
  height: 400px;
  transform: rotate(-18deg);
  border-radius: 20px;
  background: linear-gradient(rgb(5, 33, 23) 0%, rgba(32, 93, 55, 0) 100%);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: -1;
}

.main .content .presale-card-wrapper .info {
  position: absolute;
  display: flex;
  width: 270px;
  height: 400px;
  top: 130px;
  right: -32%;
  gap: 20px;
  padding: 30px 30px 30px 40px;
  text-align: left;
  font-size: 16px;
  border-radius: 20px;
  background: linear-gradient(rgba(82, 79, 31, 0) 0%, rgb(29, 32, 15) 100%);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  align-items: center;
  justify-content: center;
  z-index: -1;
  transition: all 0.3s;
  flex-direction: column;
}

.main .content .presale-card-wrapper .info svg {
  animation: scaleWarning .75s infinite alternate
}


@keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.main .content .presale-card {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: #092b1ee6;
  border: 2px solid #ffffff1a;
  border-radius: 20px;
}

.main .content .presale-card-body {
  padding: 30px;
}

.main .content .presale-card-body h4 {
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.main .content .presale-card-body h4 .icon {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 25px;
}

.main .content .presale-card-body h4 .icon img {
  max-width: 17px;
}

.main .content .presale-card-body .card-body__task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
  background: #041c14;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  transition: all 0.3s;
}


.main .content .presale-card-body .card-body__task.active {
  pointer-events: none;
}

.main .content .presale-card-body .card-body__task.active span {
  opacity: 0.5;
}

.main .content .presale-card-body .card-body__task span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main .content .presale-card-body .card-body__task span img {
  width: 20px;
  height: 20px;
}

.main .content .presale-card-body .card-body__task:hover {
  background: rgba(4, 17, 16, 0.5);
}

.main .content .presale-card-body .card-body__input {
  position: relative;
}

.main .content .presale-card-body .card-body__input input {
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: #041c14;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin-bottom: 30px;
  transition: all 0.3s;
  color: #fff;
}

.main .content .presale-card-body .card-body__input.active {
  pointer-events: none;
}

.main .content .presale-card-body .card-body__input.active input {
  color: rgb(255 255 255 / 50%);
}

.main .content .presale-card-body .success {
  font-size: 20px;
  color: #36ff92;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.main .content .presale-card-body .card-body__input input:hover {
  background: rgba(4, 17, 16, 0.5);
}

.main .content .presale-card-body .card-body__input svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #fff;
}

.main .content .presale-card-body .card-body__btn {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ffffff1a;
}

.main .content .presale-card-body .card-body__btn button {
  width: auto;
  padding: 10px 20px;
}

.connect .btn-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.main .scroll-slider-wrapper {
  position: absolute;
  height: 285px;
  bottom: 380px;
  left: -20px;
  width: calc(100% + 40px);
  padding: 100px 0px;
  overflow: hidden;
  z-index: -2 !important;
}

.main .scroll-slider-wrapper::before {
  content: "";
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.15);
  transform: rotate(6deg);
  z-index: -1;
}

.main .scroll-slider-wrapper::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.15);
  transform: rotate(-6deg);
  z-index: -1;
}

.main .scroll-slider-content {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg,
      rgb(29, 255, 150) 0%,
      rgb(188, 255, 123) 100%);
  backdrop-filter: blur(10px);
  transform: rotate(-6deg);
}

.main .scroll-slider-content .item {
  margin: 0px 30px;
  height: 80px;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
  padding-left: 30px;
  display: flex !important;
}

.main .scroll-slider-content .item h2 {
  color: rgb(17, 17, 17);
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 60px;
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.main .scroll-slider-content .item img {
  width: 60px;
}

/* Footer */
.footer {
  position: relative;
  padding: 50px 0;
}

.footer .logo {
  max-width: 90px;
  margin-bottom: 30px;
}

.footer p {
  margin-bottom: 30px;
}

.footer .social {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 30px;
}

.footer .social a {
  height: var(--icon-size, 48px);
  width: var(--icon-size, 48px);
  line-height: 48px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 10px;
  background: #ffffff14;
  transition: all 0.3s;
}

.footer .social a:hover {
  background: #fdcb02;
}

.footer .social a img {
  max-width: 20px;
}

.footer .copy {
  margin: 30px 0 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.toast {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  font-weight: 700;
  color: #d94c48;
  border: 1px solid #d94c48;
  background: rgb(44 17 30);
}

.toast-header {
  background: transparent;
  border: none;
  color: #d94c48;
  font-weight: 300;
}

.toast-header .btn-close {
  filter: invert(1);
}

.btn-mob {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1200px) {
  .main .content .presale-card-wrapper {
    width: 600px;
  }

  .main .content .presale-card-wrapper .info {
    width: 240px;
    right: -37%;
  }
}

@media screen and (max-width: 992px) {
  .main .content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .main .content p {
    font-size: 16px;
  }

  .main .content .presale-card-wrapper {
    width: 600px;
    padding-bottom: 90px;
  }

  .main .content .presale-card-wrapper .info {
    position: relative;
    width: 100%;
    left: 0;
    bottom: 0;
    top: unset;
    height: 180px;
    border-radius: 0 0 20px 20px;
    transform: rotate(0deg);
  }

  .main .content .presale-card-wrapper .info:hover {
    right: 0;
  }

  .main .content .presale-card-wrapper::before {
    display: none;
  }

  .main .scroll-slider-wrapper::before {
    transform: rotate(11deg);
  }

  .main .scroll-slider-wrapper::after {
    transform: rotate(-11deg);
  }

  .btn-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .btn-mob button {
    width: auto;
    padding: 8px 25px;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 767px) {
  .main .content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {

  .h5,
  h5 {
    font-size: 1.1rem;
  }

  .navbar-custom {
    height: 80px;
  }

  .navbar-custom .container {
    padding: 20px;
  }

  .main .content h1 {
    font-size: 30px;
  }

  .main .content .presale-card-body {
    padding: 20px;
  }

  .main .content .presale-card-body h4 {
    font-size: 16px;
  }

  .btn-st {
    text-transform: capitalize;
  }

  .main .content .presale-card-wrapper .info {
    height: auto;
    text-align: center;
  }

  .main .content .presale-card-wrapper {
    padding-bottom: 10px;
  }

  .main .scroll-slider-wrapper {
    display: none;
  }
}

.modal {
  --bs-modal-zindex: 9;
}

.modal-header {
  border: none;
}

.btn-close {
  width: 5px;
  height: 5px;
  background: transparent url(./img/icons/close.svg) center/1em auto no-repeat;
  background-size: cover;
}

.modal-title {
  font-size: 20px;
}

.modal-content {
  background-color: #0e1212;
  border: 2px solid rgb(38 38 38);
}

.modal-backdrop {
  --bs-backdrop-zindex: 9;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.modal-footer .btn-secondary {
  background: rgb(142 246 125 / 20%);
  border: none;
}